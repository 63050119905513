.title-bar {
  padding-top: 1.25rem;
  color: #9fa2b4;
}

.event-item-list {
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

.ind-item-title-display {
  font-family: Mulish;
  color: #2a2c2e;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  margin-bottom: 0;
}

.estimated-budget-display {
  display: inline-block;
  text-align: left;
}

.amount-display {
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  font-style: normal;
  color: #252733;
}

.action-buttons-display {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.action-badge {
  width: 150px;
  height: 30px;
  margin: 3px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-color: #198754;
}

.expense-container {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  padding: 32px;
}

.expense-container-inner {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.nav-link-container {
  padding: 0 2%;
}

.div-space {
  width: 250px;
}

.nav-link-font-size {
  font-size: 14px;
}

.nav-link-filter-icon {
  height: 15px;
  width: 14px;
  margin-right: 10px;
}

.nav-link-sort-icon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.nav-link-refresh-icon {
  height: 22px;
  width: 19px;
  margin-right: 10px;
}

.event-loading-style {
  border-top: 0.5px solid #9fa2b4;
  text-align: center;
  color: #9fa2b4;
  padding-top: 3%;
  padding-bottom: 4%;
  font-size: 14px;
}

.pb-3per {
  padding-bottom: 3%;
}

.modal-title-style {
  display: inline-flex;
}

.modal-style {
  border: none;
  margin: 4%;
  margin-bottom: 0;
}

.modal-space {
  width: 87%;
  margin: 1rem auto;
  border-width: 2px;
  margin-top: -5px;
}

.modal-body-style {
  max-height: 70vh;
  overflow-y: auto;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.event-header-style {
  font-weight: bold;
  font-size: 1.2em;
  margin-left: 10px;
}

.desc-of-expense {
  background-color: #dedede;
  height: 116px;
  border-radius: 25px;
  resize: none;
}

.upload-receipt {
  background-color: #dedede;
  height: 116px;
  display: none;
}

.upload-receipt-label {
  height: 116px;
  background-color: #dedede;
  border-radius: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-prev-style {
  width: 40px;
  height: 40px;
}

.amount {
  background-color: #dedede;
  border-radius: 25px;
}

.save-width {
  width: 121px;
}

.table-container {
  max-height: calc(63vh - 350px);
  overflow-y: auto;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.table-style {
  width: 100%;
  table-layout: fixed;
}

.table-head-style {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.table-head-row-style {
  border-bottom: 1px solid #ccc;
}

.th-left {
  text-align: left;
  white-space: nowrap;
}

.th-center {
  text-align: center;
  white-space: nowrap;
}

.expense-description {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expense-receipt {
  width: 50px;
  height: 50px;
}

.row-clicked-lightgray {
  border-bottom: 1px solid #ccc;
  background-color: lightgray;
}

.row-clicked-inherit {
  border-bottom: 1px solid #ccc;
  background-color: inherit;
}

.expense-amount {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.total-amount {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 46px;
  margin-top: 10px;
}

.submit-button {
  display: flex;
  align-items: right;
  justify-content: right;
  height: 50px;
  padding-right: 76px;
  padding-bottom: 10px;
  margin-top: 10px;
}
