/* .events-details {
    background-color: #F5F5F5;
    width: 100vw;
    height: 100vh;
    border: solid 1.5px #DFE0EB;
    border-radius: 18px;
    font-size: 100%;
    background-color: #FFFFFF;
    position: relative;
  } */

/* nav-list styles */

.nav-options {
    position: relative;
    top: 3%;
}

.refresh {
    position: absolute;
    left: 80%;
}

.sort {
    position: absolute;
    left: 89%;
}

.filter {
    position: absolute;
    left: 95%;
}

.refresh-icon {
    height: 22px;
    width: 22px;
    margin: 0;
    position: absolute;
    right: 110%;
}
.sort-icon {
    height: 15px;
    width: 20px;
    margin: 0;
    position: absolute;
    right: 119%;
    top: 20%;
}
.filter-icon {
    height: 13px;
    width: 13px;
    margin: 0;
    position: absolute;
    right: 120%;
    top: 20%;
}

/* current events section */
#current-events-registered {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #9FA2B4;
    position: absolute;
    top: 11%;
    left: 3%;
}
hr {
    border: solid 1.5px #DFE0EB;
    position: relative;
    top: 12%;
}
#no-events {
    font-size: 14px;
    color: #9FA2B4;
    text-align: center; 
    font-weight: 700;
    letter-spacing: 0.2px;
    position: relative;
    top: 14%;
}

/* unregistered events section */

#unregistered-events-table {
    width: 100%;
    border-collapse: collapse;
    margin: 3% 10% 0 0;
    position: relative;
    top: 15%;
    text-align: center;
}
table > tr {
    border-bottom: solid 1.5px #DFE0EB;
}
td {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
}

#event-head-title {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;
    padding-left: 3%;
    padding-bottom: 1%;
}
#event-head-date{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;
    padding-bottom: 1%;
}

#event-head-category{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;
    padding-bottom: 1%;
}
#event-head-action{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;
    padding-bottom: 1%;
}

.caution-icon {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    position: absolute;
    left: 2%;
}
.view-details {
    text-decoration: none;
    color: #2B8000;
}

button:focus {
    background-color: #61a83d;
    color: #ffffffd3;
}
button:active {
    background-color: #1a330e;
    color: #DFE0EB;
}

.circle-for-back-option {
    position: absolute;
    left: 14.2%;
    top: 3.3%;
    height: 30px;
    width: 30px;
}

.arrow-for-back-option {
    position: absolute;
    left: 14.6%;
    top: 4.1%;
    height: 15px;
    width: 15px;
    
}
.back-to-top-button > p {
    position: fixed;
	bottom: 0;
	left: 5%;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.back-to-top-circle {
    position: fixed;
	bottom: 1%;
	left: 9%;
}
.back-to-top-arrow {
    position: absolute;
    top: 94.7%;
    left: 10.16%;
    height: 16px;
}


.EventModalLink {
color: black;
text-decoration: none;

}






