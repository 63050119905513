@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap');

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

body {
  margin: 0;
  font-family: 'Mulish' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  text-align: center;
  overflow: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  width: 15%;
  height: 100%;
  position: fixed;
  background-color: #ffffff;
  float: left;
}

.div1 {
  width: 85%;
  float: right;
  background-color: #f5f5f5;
  overflow: overflow;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
}

.overviewDiv1 {
  width: 85%;
  float: right;
  background-color: #f5f5f5;
  overflow: overflow;
}

.div1 #p {
  text-align: right;
}

.overviewDiv1 #p {
  text-align: right;
}

.headerRight {
  text-align: right;
}

.sidebarContent > img {
  width: 160px;
  height: 37px;
  margin-left: 17px;
  margin-top: 24px;
}

.sidebarContent {
  height: 100%;
  left: 0px;
  right: 0px;
}

.sidebarContent > p {
  width: 100px;
  height: 20px;
  margin-top: 52px;
  margin-left: 24px;
  color: #71757b;
}

.sidebarContent > ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebarContent > ul > li {
  width: max-content;
  height: 40px;
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 8px 24px;
}

.sidebarContent > ul > li > img {
  width: 20px;
  height: 20px;
  margin-bottom: 7px;
  margin-right: 10px;

  /* Brand - TELUS Purple */
}

.headerLeft > p {
  margin-top: 30px;
  margin-left: 20px;
  position: absolute;
  width: 125px;
  height: 36px;
  font-family: 'helvetica';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.headerRight > div {
  width: auto;
  height: 17px;
  margin-top: 18px;
  clear: both;
  margin-right: 30px;
  word-spacing: 5px;
  color: #000000;
  display: inline-block;
}

.headerRightP {
  margin-top: 4px;
  margin-bottom: 1rem;
}

.headerRightP {
  margin-top: 4px;
  margin-bottom: 1rem;
}

.notification {
  width: 18px;
  height: 21px;
}

.userPic {
  width: 51px;
  height: 51px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.sidebarContent > ul > li a {
  color: black;
  text-decoration: none;
}

.sidebarContent > ul > li a:focus {
  text-decoration: underline;
}

.circle {
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  right: 0px;
  top: calc(50% - 44px / 2);
  background: #66cc00;
  border: 1.5px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(38, 39, 42, 0.04);
  border-radius: 50%;
}

.circle > p {
  text-align: center;
  padding-top: 3%;
  font-weight: 700;
}

.event-header-left > p {
  margin-top: 30px;
  margin-left: 20px;
  position: absolute;
  width: 125px;
  height: 36px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.view-all-events {
  text-decoration: none;
  color: #2b8000;
}

.custom-modal {
  border-bottom: 0 none;
}

.modal-xl {
  width: 69% !important;
}

.ModalBody > div {
  display: inline-block;
  margin-bottom: 48px;
}

.ModalBodyLeft {
  margin-left: 40px;
  width: 382px;
  height: 208px;
  float: left;
}

.ModalBodyRight {
  margin-left: 40px;
  width: 590px;
  height: 157.28px;
}

.ModalBodyRightSubDiv > a {
  width: 40px;
  height: 40px;
}

.ModalBodyLeft > img {
  width: 382px;
  height: 208px;
}

.ModalBodyRight > p {
  width: auto;
}

.HNS {
  margin-top: 56px;
}

#ModalButton {
  color: #2b8000;
}

.ModalBodyRightSubDiv {
  margin-top: 12px;
}

.ModalBodyRightSubDiv > a {
  width: 40px;
  height: 40px;
}

.ModalBodyRightSubDiv > img {
  width: 43px;
  height: 43px;
}

.bookmarklogo {
  padding: 9px;
}

.ModalBadge {
  background-color: #c12335;
  margin-right: 12px;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 28px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 142px;
}

.floating-div {
  position: absolute;
  top: 170px;
  right: 170px;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
}

.floating-div::before {
  content: '';
  position: absolute;
  top: -15px;
  right: 10px;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-bottom-color: lightgrey;
}

.floating-div2 {
  position: absolute;
  top: 170px;
  right: 85px;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
}

.floating-div2::before {
  content: '';
  position: absolute;
  top: -15px;
  right: 10px;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-bottom-color: lightgrey;
}

.textStyle {
  color: black;
  transition: color 0.3s;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
}

.textStyle:focus {
  color: white;
}

.custom-progress-bar .progress-bar {
  border-radius: 10px;
}

.custom-progress-bar .progress-bar.custom-progress-bar-success {
  background-color: #66cc00;
}

.custom-timepicker {
  background-color: #dedede !important;
  border-radius: 25px !important;
}

.react-datepicker-wrapper {
  width: -webkit-fill-available !important;
}

.back-to-top-circle:hover {
  filter: drop-shadow(3px 3px 3px gray);
}

.tags a {
  display: inline-block;
  height: 24px;
  line-height: 23px;
  position: relative;
  margin: 0 12px 8px 0;
  padding: 0 12px 0 10px;
  background: #777;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.tags a:before {
  content: '';
  position: absolute;
  top: 10px;
  right: 1px;
  float: left;
  width: 5px;
  height: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
}

.tags a:after {
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #777;
  border-style: solid;
  border-width: 12px 0 12px 12px;
}

.tags a.color1 {
  background: #f58220;
}
.tags a.color1:after {
  border-color: transparent transparent transparent #f58220;
}
.tags a.color2 {
  background: #97c224;
}
.tags a.color2:after {
  border-color: transparent transparent transparent #97c224;
}
.tags a.color3 {
  background: #de3f3e;
}
.tags a.color3:after {
  border-color: transparent transparent transparent #de3f3e;
}
.tags a.color4 {
  background: #ec008c;
}
.tags a.color4:after {
  border-color: transparent transparent transparent #ec008c;
}
.tags a.color5 {
  background: #00a6df;
}
.tags a.color5:after {
  border-color: transparent transparent transparent #00a6df;
}
.tags a:hover {
  background: #b4b3b3 !important;
  color: white !important;
}
.tags a:hover:after {
  border-color: transparent transparent transparent #b4b3b3 !important;
}

.small a {
  height: 21px;
  line-height: 21px;
  float: none;
  font-size: 11px;
}

.small a:before {
  right: 0;
  top: 8px;
  border-width: 10px 0 10px 10px;
}

.small a:after {
  right: -11px;
  top: 0;
  border-width: 11px 0 11px 11px;
}
